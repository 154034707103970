body{
  background-color: #d3d3d3c5;
}

.titlediv{
  margin-top: 0 !important;
  margin-bottom: 10px;
}
.container-fluid {
  padding: 0;
}

.marginClass {
padding-top: 80px;
}

/* home css goes from here */

.typing-demo {
  width: 12ch;
  animation: typing 2s steps(12), blink .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

.banner{
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 97.5vw;
  padding: 15px;
  margin-top: 80px;
}

.left{
  width:60%;
  float: left;
}

.right{
  width:40%;
   float: right;
}

@media screen and (max-width: 576px) {
  .right{
    display: none;
  }

  .left{
    width: 100%;
  }

  .banner{
    height: 300px;
  }

  .ram {
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    padding: 0;
  }

  .ram .card{
    padding: 10px;
    margin-top:10px;
  }

  .card img {
    width: 50px;
  }

  .card-body{
   padding: 0;
  }

  .row .card-title{
    padding-top: 0;
    margin-top: 15px;
  }

  
}

.row{
  margin: 0;
  
}

.main {
  background-color: white;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 97.5vw;
  margin-left: auto;
  margin-right: auto;
  max-height: 350px;
}

.notice{
  padding:0 7% 0 7%;
    overflow: scroll;

}

.banner h2 {
  color: #10c482;
}

.main ul{
  flex-direction: column;
}

.main li{
  list-style: disc;
}

.main p {
  color: #555;
  font-size: large;
}

.ad{
  max-height: 300px;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 90%;
  max-width: 300px;
}

.card{
  margin-top: 3vh;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 
}


.card-title{
  padding-top: 26px;
}


.card-body{
  transition: 0.5s ease-in-out ;
}

.card-body:hover{
  transform: scale(0.95); transition: 0.5s ease-in-out ;
}

.rounded-circle {
  border-width: 0.3rem;
border-style: solid;
border-color: #04AA6D;
  position: relative;
  width:80px;
  float: left;
}

a{
  text-decoration: none;
  color: rgba(0,0,0,.9);
}

a:hover{
  color: rgba(0, 0, 0, 0.6);
}

/* quiz css from here */
.correctAttempts>ul, .wrongAnswer>ul, .unattemptedQuestions>ul{
  padding-left: 0px;
}

.correctAttempts li, .wrongAnswer li, .unattemptedQuestions li{
  font-size: 20px;
  list-style: none;
  margin: 0;
}


.mybtn{ 
  border: 1px solid transparent;
  background-color: transparent;
  color: #04AA6D;
  padding: 10px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 20px 10px 20px; /* Top left right bottom */
  cursor: pointer;
  border-color: #04AA6D;
  border-radius: 5px;
  outline: none !important;
 transition: 250ms ease-in-out;

 
}

.mybtn:hover {
  color:white;
  background-color: #04aa6df3;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.fr{
  float: inline-end;
}

label{
  display: block;
  background-color: transparent;
  padding: 10px 22px;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-color: #04AA6D;
  border-radius: 7px;
  outline: none !important;
 transition: 250ms ease-in-out;
}

label:hover {
  color:white;
  background-color: #04aa6df3;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

input[type="radio"]:checked + label {
  color:white;
  background-color: #04aa6df3;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

input[type="radio"] {
  display: none;
}

.resCol:hover{
  background-color:initial;
  color: black;
  box-shadow: none;
}

.wrong input[type="radio"]:checked + label {
  color:white;
  background-color: rgba(255, 0, 0, 0.89);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

button{
  border-color: #04AA6D;
  border-radius: 5px;
  outline: none !important;
 transition: 250ms ease-in-out;
}

.completeAnalysis{
 display: flex;
 flex-direction: column;
}

.completeAnalysis div ul{
  display: flex;
  flex-direction: column;
}

.custom-link:hover{
  color: #0087ca;;
  text-decoration: none;
}